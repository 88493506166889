const BASE = '[Discounts]';

const DiscountsTypes = {
  FETCH_DISCOUNTS: `${BASE} FETCH_DISCOUNTS`,
  FETCH_DISCOUNTS_SUCCESS: `${BASE} FETCH_DISCOUNTS_SUCCESS`,
  FETCH_DISCOUNTS_FAIL: `${BASE} FETCH_DISCOUNTS_FAIL`,
  FETCH_SCORE_CARD_DISCOUNTS: `${BASE} FETCH_SCORE_CARD_DISCOUNTS`,
  FETCH_SCORE_CARD_DISCOUNTS_SUCCESS: `${BASE} FETCH_SCORE_CARD_DISCOUNTS_SUCCESS`,
  FETCH_SCORE_CARD_DISCOUNTS_FAIL: `${BASE} FETCH_SCORE_CARD_DISCOUNTS_FAIL`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
  CHANGE_SEARCH_VALUE: `${BASE} CHANGE_SEARCH_VALUE`,
  CHANGE_START_PERIOD: `${BASE} CHANGE_START_PERIOD`,
  CHANGE_END_PERIOD: `${BASE} CHANGE_END_PERIOD`,
  CHANGE_PROCESS: `${BASE} CHANGE_PROCESS`,
  GET_REPORT: `${BASE} GET_REPORT`,
  GET_REPORT_SUCCESS: `${BASE} GET_REPORT_SUCCESS`,
  GET_REPORT_FAIL: `${BASE} GET_REPORT_FAIL`,
  GET_REPORT_DISCOUNT_TYPE: `${BASE} GET_REPORT_DISCOUNT_TYPE`,
  GET_REPORT_DISCOUNT_TYPE_SUCCESS: `${BASE} GET_REPORT_DISCOUNT_TYPE_SUCCESS`,
  GET_REPORT_DISCOUNT_TYPE_FAIL: `${BASE} GET_REPORT_DISCOUNT_TYPE_FAIL`,
};

export default DiscountsTypes;
