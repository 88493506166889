import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import PageTitle from 'content/features/other/components/PageTitle';
import { getReportDiscountType } from 'content/features/summary-discounts/discounts.actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingDiscountTypeDownloadSelector } from 'content/features/summary-discounts/discounts.selector';

function ItemTypeTitle({ setModal }) {
  const dispatch = useDispatch();
  const loadingDiscountType = useSelector(loadingDiscountTypeDownloadSelector);
  const handleClick = () => {
    setModal({
      isOpen: true,
      isNew: true,
    });
  };
  const handleDownload = () => {
    dispatch(getReportDiscountType());
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <PageTitle title="Lista tipos de descuentos" />
      </Grid>
      <Grid item container lg={5} md={12} justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button variant="contained" size="large" onClick={handleClick}>
            Crear descuento
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            onClick={handleDownload}
            loading={loadingDiscountType}
            variant="contained"
            size="large">
            Descarga reporte
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ItemTypeTitle;
