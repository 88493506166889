export const discountsDataSelector = (state) => state.discounts.data.items;
export const totalsSelector = (state) => state.discounts.data.totals;
export const loadingSelector = (state) => state.discounts.loaders.page;
export const currentPageSelector = (state) => state.discounts.currentPage;
export const totalPagesSelector = (state) => state.discounts.totalPages;
export const searchValueSelector = (state) => state.discounts.searchValue;
export const startPeriodValueSelector = (state) => state.discounts.startPeriodValue;
export const endPeriodValueSelector = (state) => state.discounts.endPeriodValue;
export const processValueSelector = (state) => state.discounts.processValue;
export const loadingButtonSelector = (state) => state.discounts.loaders.button;
export const loadingDiscountTypeDownloadSelector = (state) =>
  state.discounts.loaders.discountTypeDownload;

const DiscountsSelector = {
  loading: loadingSelector,
  discountsData: discountsDataSelector,
  totalsData: totalsSelector,
  currentPage: currentPageSelector,
  totalPages: totalPagesSelector,
  searchValue: searchValueSelector,
  startPeriodValue: startPeriodValueSelector,
  endPeriodValue: endPeriodValueSelector,
  processValue: processValueSelector,
  loadingButton: loadingButtonSelector,
  loadingDiscountTypeDownload: loadingDiscountTypeDownloadSelector,
};

export default DiscountsSelector;
