import { createReducer } from '@reduxjs/toolkit';

import {
  fetchDiscounts,
  fetchDiscountsSuccess,
  fetchDiscountsFail,
  fetchScoreCardDiscounts,
  fetchScoreCardDiscountsSuccess,
  fetchScoreCardDiscountsFail,
  changePagination,
  changeSearchValue,
  changeStartPeriodValue,
  changeEndPeriodValue,
  changeProcessValue,
  getReport,
  getReportSuccess,
  getReportFail,
  getReportDiscountType,
  getReportDiscountTypeSuccess,
  getReportDiscountTypeFail,
} from './discounts.actions';

const initialState = {
  loaders: {
    page: false,
    button: false,
    discountTypeDownload: false,
  },
  data: {
    items: [],
    totals: {
      previousBalanceAmount: 0,
      asignedMonthAmount: 0,
      discountedAmount: 0,
      actualBalanceAmount: 0,
    },
  },
  currentPage: 1,
  totalPages: 0,
  searchValue: '',
  startPeriodValue: '01-09-2023', // TODO: PRIMER DIA DEL MES ACTUAL
  endPeriodValue: '01-09-2023', // TODO: ULTIMO DIA DEL MES ACTUAL
  processValue: '0',
};

const DiscountsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchDiscounts, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchDiscountsSuccess, (state, action) => {
      state.loaders.page = false;
      state.data.items = action.payload.objects;
      state.data.totalPages = action.payload.totalPages;
      state.totalPages = action.payload.totalPages;
    })
    .addCase(fetchDiscountsFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchScoreCardDiscounts, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchScoreCardDiscountsSuccess, (state, action) => {
      state.loaders.page = false;
      state.data.totals.previousBalanceAmount = action.payload.previousBalanceAmount;
      state.data.totals.asignedMonthAmount = action.payload.asignedMonthAmount;
      state.data.totals.discountedAmount = action.payload.discountedAmount;
      state.data.totals.actualBalanceAmount = action.payload.actualBalanceAmount;
    })
    .addCase(fetchScoreCardDiscountsFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(changePagination, (state, action) => {
      state.currentPage = action.payload.currentPage;
    })
    .addCase(changeSearchValue, (state, action) => {
      state.searchValue = action.payload.searchValue;
      state.currentPage = 1;
    })
    .addCase(changeStartPeriodValue, (state, action) => {
      state.startPeriodValue = action.payload.startPeriodValue;
      state.currentPage = 1;
    })
    .addCase(changeEndPeriodValue, (state, action) => {
      state.endPeriodValue = action.payload.endPeriodValue;
      state.currentPage = 1;
    })
    .addCase(changeProcessValue, (state, action) => {
      state.processValue = action.payload.processValue;
      state.currentPage = 1;
    })
    .addCase(getReport, (state) => {
      state.loaders.button = true;
    })
    .addCase(getReportSuccess, (state) => {
      state.loaders.button = false;
    })
    .addCase(getReportFail, (state) => {
      state.loaders.button = false;
    })
    .addCase(getReportDiscountType, (state) => {
      state.loaders.discountTypeDownload = true;
    })
    .addCase(getReportDiscountTypeSuccess, (state) => {
      state.loaders.discountTypeDownload = false;
    })
    .addCase(getReportDiscountTypeFail, (state) => {
      state.loaders.discountTypeDownload = false;
    });
});

export default DiscountsReducer;
