import { takeLatest, spawn, put } from 'redux-saga/effects';
import moment from 'moment';

/* Project */
import apiRequest, { apiSuccess } from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import { getItemInStorage } from 'utils/functions';
import {
  fetchDiscounts,
  fetchDiscountsSuccess,
  fetchDiscountsFail,
  fetchScoreCardDiscounts,
  fetchScoreCardDiscountsSuccess,
  fetchScoreCardDiscountsFail,
  getReport,
  getReportSuccess,
  getReportFail,
  getReportDiscountTypeSuccess,
  getReportDiscountTypeFail,
  getReportDiscountType,
} from './discounts.actions';

function* fetchDiscountsItemType() {
  yield takeLatest(fetchDiscounts, function* fetchDiscountsToApi(action) {
    const { pageSize, actualPage, searchValue, startPeriodValue, endPeriodValue, processValue } =
      action.payload;
    const response = yield apiRequest(
      `api/v1/item?start_date=${startPeriodValue}&end_date=${endPeriodValue}&take=${pageSize}&page=${actualPage}&search=${searchValue}&isReissued=${processValue}`,
      { method: 'get' },
    );
    if (response) {
      yield put(
        fetchDiscountsSuccess({
          objects: response.objects,
          currentPage: response.pagination.currentPage,
          totalPages: response.pagination.totalPages,
        }),
      );
    } else {
      yield put(fetchDiscountsFail());
      defaultErrorToast({ message: 'Falló la carga de descuentos.' });
    }
  });
}

function* fetchDiscountsScoreCard() {
  yield takeLatest(fetchScoreCardDiscounts, function* fetchDiscountsScoreCardToApi(action) {
    const { startPeriodValue, endPeriodValue, processValue } = action.payload;
    const response = yield apiRequest(
      `api/v1/item/total?start_date=${startPeriodValue}&end_date=${endPeriodValue}&isReissued=${processValue}`,
      {
        method: 'get',
      },
    );
    if (response) {
      yield put(
        fetchScoreCardDiscountsSuccess({
          previousBalanceAmount: response.previousBalanceAmount,
          asignedMonthAmount: response.asignedMonthAmount,
          discountedAmount: response.discountedAmount,
          actualBalanceAmount: response.actualBalanceAmount,
        }),
      );
    } else {
      yield put(fetchScoreCardDiscountsFail());
      defaultErrorToast({ message: 'Falló la carga de descuentos.' });
    }
  });
}

function* getReports() {
  yield takeLatest(getReport, function* getReportsToApi(action) {
    const { searchValue, startPeriodValue, endPeriodValue, processValue } = action.payload;
    try {
      const csrfToken = window.document.cookie
        .split('; ')
        .find((cookie) => cookie.trim().startsWith('XSRF-TOKEN'));
      const session = getItemInStorage('user');

      const date = moment().format('DD-MM-YYYY');
      const headers = {
        'Content-Type': 'blob',
        'X-CSRF-Token': csrfToken?.split('=')[1],
        Authorization: `Bearer ${session.token}`,
      };

      fetch(
        `${window.location.origin}/api/v1/excel/download?start_date=${startPeriodValue}&end_date=${endPeriodValue}&search=${searchValue}&isReissued=${processValue}`,
        {
          method: 'GET',
          responseType: 'arraybuffer',
          headers,
        },
      )
        .then((response) => response.blob())
        .then((myBlob) => {
          const url = window.URL.createObjectURL(
            new Blob([myBlob], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Detalle descuentos-${date}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
      yield put(getReportSuccess());
    } catch {
      yield put(getReportFail());
    }
  });
}

function* getReportDiscountTypes() {
  yield takeLatest(getReportDiscountType, function* getReportDiscountTypeToApi() {
    try {
      const csrfToken = window.document.cookie
        .split('; ')
        .find((cookie) => cookie.trim().startsWith('XSRF-TOKEN'));
      const session = getItemInStorage('user');

      const headers = {
        'Content-Type': 'blob',
        'X-CSRF-Token': csrfToken?.split('=')[1],
        Authorization: `Bearer ${session.token}`,
      };

      fetch(`${window.location.origin}/api/v1/excel/download/discountTypes`, {
        method: 'GET',
        responseType: 'arraybuffer',
        headers,
      })
        .then((response) => response.blob())
        .then((myBlob) => {
          const url = window.URL.createObjectURL(
            new Blob([myBlob], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Tipos de descuentos.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
      yield put(getReportDiscountTypeSuccess());
    } catch {
      yield put(getReportDiscountTypeFail());
      yield apiSuccess(defaultErrorToast, { message: 'Falló la descarga de tipos de descuentos.' });
    }
  });
}

export default function* DiscountsEmployeeSaga() {
  yield spawn(fetchDiscountsItemType);
  yield spawn(fetchDiscountsScoreCard);
  yield spawn(getReports);
  yield spawn(getReportDiscountTypes);
}
