import { createAction } from '@reduxjs/toolkit';
import BulkLoadTypes from './bulkLoad.type';

export const loadDataBase = createAction(BulkLoadTypes.GET_PAYROLL);
export const loadDataBaseSuccess = createAction(BulkLoadTypes.GET_PAYROLL_SUCCESS);
export const loadDataBaseFail = createAction(BulkLoadTypes.GET_PAYROLL_FAIL);

export const loadingProcess = createAction(BulkLoadTypes.LOADING_PROCESS, ({ progress }) => ({
  payload: {
    progress,
  },
}));

export const insertedRecords = createAction(BulkLoadTypes.INSERTED_RECORD, ({ record }) => ({
  payload: {
    record,
  },
}));

export const lastPeriod = createAction(BulkLoadTypes.GET_LAST_PERIOD);

export const lastPeriodSuccess = createAction(BulkLoadTypes.GET_LAST_PERIOD_SUCCESS, (payload) => ({
  payload: {
    ...payload,
    objects: payload.objects,
  },
}));

export const lastPeriodFail = createAction(BulkLoadTypes.GET_LAST_PERIOD_FAIL);

export const setLoadDate = createAction(BulkLoadTypes.SET_LOAD_DATE, ({ date }) => ({
  payload: {
    date,
  },
}));

export const sendItems = createAction(BulkLoadTypes.SEND_ITEMS);
export const sendItemsSuccess = createAction(BulkLoadTypes.SEND_ITEMS_SUCCESS);
export const sendItemsFail = createAction(BulkLoadTypes.SEND_ITEMS_FAIL);

export const sendingProcess = createAction(BulkLoadTypes.SENDING_PROCESS, ({ progress }) => ({
  payload: {
    progress,
  },
}));

export const updatedRecords = createAction(BulkLoadTypes.UPDATED_RECORD, ({ record }) => ({
  payload: {
    record,
  },
}));

export const lastUpdate = createAction(BulkLoadTypes.GET_LAST_UPDATE);

export const lastUpdateSuccess = createAction(BulkLoadTypes.GET_LAST_UPDATE_SUCCESS, (payload) => ({
  payload: {
    ...payload,
    objects: payload.objects,
  },
}));

export const lastUpdateFail = createAction(BulkLoadTypes.GET_LAST_UPDATE_FAIL);

export const setSendDate = createAction(BulkLoadTypes.SET_SEND_DATE, ({ date }) => ({
  payload: {
    date,
  },
}));

export const getClosedPayrollLoad = createAction(BulkLoadTypes.GET_CLOSED_PAYROLL_LOAD);

export const getClosedPayrollSend = createAction(BulkLoadTypes.GET_CLOSED_PAYROLL_SEND);

export const getClosedPayrollSuccess = createAction(
  BulkLoadTypes.GET_CLOSED_PAYROLL_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const getClosedPayrollFail = createAction(BulkLoadTypes.GET_CLOSED_PAYROLL_FAIL);

export const getApvReport = createAction(BulkLoadTypes.GET_APV_REPORT);

export const getApvReportSuccess = createAction(BulkLoadTypes.GET_APV_REPORT_SUCCESS);

export const getApvReportFail = createAction(BulkLoadTypes.GET_APV_REPORT_FAIL);

const BulkLoadActions = {
  loadDataBase,
  loadDataBaseSuccess,
  loadDataBaseFail,
  loadingProcess,
  insertedRecords,
  lastPeriod,
  lastPeriodSuccess,
  lastPeriodFail,
  setLoadDate,
  sendItems,
  sendItemsSuccess,
  sendItemsFail,
  sendingProcess,
  updatedRecords,
  lastUpdate,
  lastUpdateSuccess,
  lastUpdateFail,
  setSendDate,
  getClosedPayrollLoad,
  getClosedPayrollSend,
  getClosedPayrollSuccess,
  getClosedPayrollFail,
  getApvReport,
  getApvReportSuccess,
  getApvReportFail,
};

export default BulkLoadActions;
